import React, { useState, useEffect } from "react";
import Detail from "./Detail";

function App() {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    fetch("/data.json", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((json) => {
        console.log("json", new Date());
        if (json) {
          setData(json);
        }
      });
    return () => {};
  }, []);

  return (
    <>
      <Detail data={data} />
    </>
  );
}

export default App;
