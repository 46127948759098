import React, { useState, useEffect } from "react";
import DesktopUsaMap from "./desktop-usa_map";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Box, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Detail = ({ data }: { data: any }) => {
  return (
    <>
      <CssBaseline />
      <Container>
        <Grid container spacing={2} py={3}>
          {data ? (
            <>
              <Grid item xs={12}>
                <Box
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <p>
                    Election results: <strong>U.S. Senate</strong>
                  </p>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  textAlign="center"
                >
                  <span style={{ color: "#40407A" }}>
                    <strong>{data.totalDemCount} DEMS</strong>
                  </span>
                  <span style={{ color: "#AF5858" }}>
                    <strong>GOP {data.totalGopCount}</strong>
                  </span>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  textAlign="center"
                >
                  <Box
                    sx={{
                      width: `${data.barPerc[0]}%`,
                      height: 20,
                      backgroundColor: "#40407A",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      width: `${data.barPerc[1]}%`,
                      height: 20,
                      backgroundColor: "#59599B",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      width: `${data.barPerc[2]}%`,
                      height: 20,
                      backgroundColor: "#C98F8F",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      width: `${data.barPerc[3]}%`,
                      height: 20,
                      backgroundColor: "#AF5858",
                    }}
                  ></Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  textAlign="center"
                >
                  <span style={{ color: "#40407A" }}>
                    {data.demNotUpCount} NOT UP
                  </span>
                  <span>{data.seatCount} seats</span>
                  <span style={{ color: "#AF5858" }}>
                    {data.gopNotUpCount} NOT UP
                  </span>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <DesktopUsaMap data={data} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <div>
                    <p>
                      <strong>Leading/Won</strong>
                    </p>
                    <Box display="flex" flexDirection="row">
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          backgroundColor: "#40407A",
                          marginRight: 1,
                        }}
                      />
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          marginRight: 1,
                          backgroundColor: "#59599B",
                        }}
                      />
                      <span>Dems</span>
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          backgroundColor: "#AF5858",
                          marginLeft: 4,
                          marginRight: 1,
                        }}
                      />
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          marginRight: 1,
                          backgroundColor: "#C98F8F",
                        }}
                      />
                      <span>GOP</span>
                    </Box>
                  </div>
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <CircularProgress color="inherit" />
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default Detail;
